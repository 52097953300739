import React from "react"

export default function() {
  return (
    <section style={{margin: 0}}>
        <div
          className="col-md-12"
          style={{background: "#58b0da29",color: "#443636",paddingTop: "3em",paddingBottom: "2em"}}
        >
          <div style={{width: "100%"}}>
              <p>
                Sheer Analytics and Insights is a leading global consulting firm that offers a wide range of services in both B2B and B2C consulting. The firm is known for its in-depth research and analysis, customized solutions, and strong relationships with clients. Let's look at the services offered by Sheer Analytics and Insights in B2B and B2C consulting:
              </p>
              <br />
              <h1>B2B Consulting Services:</h1>
              <h2>• Market Research:</h2>
              <p>Sheer Analytics and Insights offers comprehensive market research services that provide clients with a detailed understanding of their target market. The firm uses a variety of research methods, including primary and secondary research, to gather data and insights that can be used to inform strategic decision-making.</p>
              <h2>•	Business Strategy Development:</h2>
              <p>Sheer Analytics and Insights works with clients to develop customized business strategies that are designed to improve operations and increase profitability. The firm takes a data-driven approach, using market research and industry insights to develop strategies that are tailored to the specific needs of each client.</p>
              <h2>•	Supply Chain Optimization: </h2>
              <p>Sheer Analytics and Insights offers supply chain optimization services that help clients streamline their supply chain processes and reduce costs. The firm uses a combination of data analysis, process mapping, and technology implementation to identify areas of improvement and implement solutions that increase efficiency.</p>
              <h2>•	Technology Implementation:</h2>
              <p>Sheer Analytics and Insights helps clients implement new technologies that can improve their operations and increase productivity. The firm has expertise in a wide range of technologies, including cloud computing, artificial intelligence, and the Internet of Things.</p>
              <h1>B2C Consulting Services:</h1>
              <h2>•	Consumer Research: </h2>
              <p>Sheer Analytics and Insights offers comprehensive consumer research services that help clients better understand their target audience. The firm uses a variety of research methods, including surveys, focus groups, and social media analysis, to gather data and insights that can be used to inform marketing strategies.</p>
              <h2>•	Marketing Strategy Development: </h2>
              <p>Sheer Analytics and Insights works with clients to develop customized marketing strategies that are designed to increase brand awareness and drive sales. The firm takes a data-driven approach, using consumer research and industry insights to develop strategies that are tailored to the specific needs of each client.</p>
              <h2>•	Customer Service Improvement:</h2>
              <p>Sheer Analytics and Insights offers customer service improvement services that help clients improve their customer experience. The firm uses a combination of data analysis, customer feedback, and employee training to identify areas of improvement and implement solutions that increase customer satisfaction.</p>
              <h2>•	Loyalty Program Development: </h2>
              <p>Sheer Analytics and Insights helps clients develop loyalty programs that are designed to increase customer retention and drive sales. The firm uses a data-driven approach, using consumer research and industry insights to develop programs that are tailored to the specific needs of each client.</p>
          </div>
          <p>For any queries reach us at <a href="mailto:consulting@sheeranalyticsandinsights.com">consulting@sheeranalyticsandinsights.com</a></p>
        </div>
    </section>
  )
}
