import React from "react"

import B2BB2CConsulting from "../components/Solutions/B2BB2CConsulting"
import SEO from "../components/Base/SEO"
import InnerPageBanner from "../components/Shared/InnerPageBanner"
import Layout from "../components/Base/Layout"

export default function () {
  return (
    <Layout>
      <SEO title={"sheer analytics"} />
      <InnerPageBanner headingText={"B2B B2C Consulting"} linkText={"B2B B2C Consulting"} />
      <B2BB2CConsulting />
    </Layout>
  )
}